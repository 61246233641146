/* ## Front Page
--------------------------------------------- */

.home {

	.entry-header {
		padding-top: calc(20vw + 9rem);
		padding-bottom: 20vw;

		@include breakpoint(sm) {
			padding-top: calc(20vh + 9rem);
			padding-bottom: 20vh;
		}
	}

	.display-posts-listing {
		position: relative;

		@include breakpoint(lg) {
			left: -20%;
			width: 140%;
		}
	}
}

/* ## Custom Header
--------------------------------------------- */

.wp-custom-header {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	img,
	video,
	iframe {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: auto;
		min-width: 100%;
		max-width: 100%;
		height: auto;
		min-height: 100%;
		max-height: none;
		margin: auto;
		object-fit: cover;
	}

	iframe {
		right: -25%;
		left: -25%;
		width: 150%;
		height: 150%;
	}
}
