/* ## Display Posts Shortcode
--------------------------------------------- */

.display-posts-listing {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto !important;

	.listing-item {
		position: relative;
		margin-bottom: 6vw;
		box-shadow: 0 1rem 0 -1rem rgba($dark, 0);
		list-style-type: none;

		@include transition;
		@include clearfix;

		@include breakpoint(sm) {
			margin-bottom: $margin-left;
		}

		&:before {
			position: absolute;
			z-index: 1;
			background-color: rgba($dark, 0);
			pointer-events: none;

			@include transition;
			@include center;
		}

		&:hover,
		&:focus {
			box-shadow: 0 2rem 2rem -1rem rgba($dark, 0.2);

			&:before {
				background-color: rgba($dark, 0.82);
			}

			.title {
				opacity: 1;
				transition-delay: 0.25s;
				transform: translateY(20%);
			}

			.category-display {

				a {
					opacity: 1;
					transition-delay: 0.25s;
					transform: translateY(-220%);
				}
			}
		}
	}

	.image {
		display: block;
		overflow: hidden;
		position: relative;
	}

	img {

		@include transition;
	}

	.fade-in {
		display: block;
		overflow: hidden;
		position: absolute;
		z-index: 2;
		height: 1.4em;
		color: $white;
		font-family: $font-heading;
		font-size: 2.6rem;
		line-height: 1;
		text-align: center;

		@include center;
		@include transition;
	}

	.title {
		position: absolute;
		opacity: 0;
		color: $white;
		text-decoration: none;
		transition-delay: 0.25s;
		transform: translateY(110%);
		-webkit-font-smoothing: antialiased;

		@include transition;
		@include center;
	}

	.category-display {
		display: flex;
		position: absolute;
		z-index: 1;
		font-size: 1rem;
		text-align: center;
		text-transform: uppercase;
		pointer-events: none;
		justify-content: center;
		align-items: center;

		@include center;

		span {
			display: none;
		}

		a {
			margin: 0 0.5em;
			opacity: 0;
			color: $light;
			text-decoration: none;
			transition-delay: 0.25s;
			transform: translateY(-100%);

			@include transition;
		}
	}
}

