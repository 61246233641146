/* ## Site Footer
--------------------------------------------- */

.site-footer {
	width: 100%;
	font-size: 1.8rem;

	> .wrap {
		position: relative;
		padding: 5vw;
		text-align: center;

		@include breakpoint(sm) {
			padding: 6rem 0;
		}

		> p {
			font-size: 1.2rem;
		}
	}

	.widget-area {

		@include breakpoint(sm) {
			margin-bottom: 0;
		}
	}
}

/* ## Footer Widgets
--------------------------------------------- */

.footer-widgets {
	padding: 6rem 5vw;
	text-align: center;

	@include breakpoint(sm) {
		padding: 6rem 0;
	}

	.wrap {

		@include breakpoint(sm) {
			display: flex;
			flex-wrap: wrap;
		}
	}

	.widget-title {
		font-size: 2em;

		@include breakpoint(sm) {
			font-size: 2.4em;
		}
	}

	.widget-area {
		margin: 0 auto 4rem;

		@include breakpoint(sm) {
			flex: 1;
			margin-left: 2.564102564102564%;

			&:first-of-type {
				margin-left: 0;
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		.widget {
			display: block;
			width: 100%;
			margin: 0 auto 2em;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}
