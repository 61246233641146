/* ## Avatars
--------------------------------------------- */

.avatar {
	float: left;
	margin-right: 2rem;
	border-radius: 50%;

	.alignright & {
		margin-left: 2rem;
	}
}

/* ## Genesis
--------------------------------------------- */

.breadcrumb {
	width: 100%;
	padding: 1em 7.5vw;
	border-bottom: $site-border;
	background-color: $white;
	font-size: 1.4rem;

	@include breakpoint(sm) {
		padding: 1em 6rem;
	}

	a {
		text-decoration: underline;
	}
}

.after-entry,
.author-box {
	margin-bottom: 5%;

	@include breakpoint(sm) {
		margin-bottom: 4rem;
	}
}

.archive-description {
	overflow: hidden;
	position: relative;
	padding: 25vw 0 0;

	@include breakpoint(sm) {
		padding: 20vh 0 0;
	}
}

.archive-title {
	width: 100%;
	margin-bottom: 5vw;
	text-align: center;

	@include breakpoint(sm) {
		margin-bottom: 10rem;
	}

	a {
		color: $dark;
		font-family: $font-heading;
		text-decoration: none;

		&:hover {
			color: $accent;
		}
	}

	+ p {
		display: flex;
		max-width: map-get($breakpoints, sm);
		margin-right: auto;
		margin-left: auto;
		text-align: center;
		flex-wrap: wrap;
		justify-content: center;
	}
}

/* ## Search Form
--------------------------------------------- */

.search-form {
	display: block;
	position: relative;
	width: 100%;

	@include clearfix;

	input[type="search"] {
		width: 100%;
	}

	input[type="submit"] {
		display: none;
	}

	.entry-content & {
		margin-bottom: 1em;
	}
}

/* ## Titles
--------------------------------------------- */

.author-box-title {
	font-size: 2rem;
}

/* ## WordPress
--------------------------------------------- */

a {

	&.aligncenter img {
		display: block;
		margin: 0 auto;
	}

	&.alignnone {
		display: inline-block;
	}
}

.alignleft {
	float: left;
	text-align: left;
}

.alignright {
	float: right;
	text-align: right;
}

a {

	&.alignleft,
	&.alignnone,
	&.alignright {
		max-width: 100%;
	}
}

img.centered,
.aligncenter {
	display: block;
	margin: 0 auto 1em;
}

img.alignnone,
.alignnone {
	margin-bottom: 1em;
}

a.alignleft,
img.alignleft,
.wp-caption.alignleft {
	margin: 0 1em 1em 0;
}

a.alignright,
img.alignright,
.wp-caption.alignright {
	margin: 0 0 1em 1em;
}

.wp-caption-text {
	margin: 0;
	font-size: 1.4rem;
	text-align: center;
}

.entry-content {

	p.wp-caption-text {
		margin-bottom: 0;
	}

	.wp-audio-shortcode,
	.wp-playlist,
	.wp-video {
		margin: 0 0 1em;
	}
}

.sticky,
.bypostauthor {
	background-color: transparent;
}

.post-edit-link {
	position: absolute;
	opacity: 0;
}

/* ## Pricing Table
--------------------------------------------- */

.pricing-table {

	@include clearfix;

	> div {
		margin: 0 -1px $margin-left;
		padding: 4rem;
		border: $site-border;
		background-color: $white;
	}

	.one-half {
		width: 50%;
	}

	.one-third {
		width: 33.3333333333%;
	}

	.one-fourth {
		width: 25%;
	}
}
