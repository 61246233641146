/* ## Site Header
--------------------------------------------- */

.site-header {
	position: absolute;
	z-index: 100;
	width: 100%;
	border-color: $white;

	@include transition;

	> .wrap {
		display: flex;
		position: relative;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

		@include breakpoint(md) {
			padding: 0;
			flex-wrap: nowrap;
		}
	}
}

/* ## Title Area
--------------------------------------------- */

.title-area {
	display: block;
	position: relative;
	max-width: 18rem;
	height: 100%;
	margin: 0 auto 0 0;
	padding: 1.382em 0;
	flex: 1;

	@include breakpoint(md) {
		max-width: 24rem;
	}
}

.site-title {
	margin-bottom: 0.382em;
	font-family: $font-heading;
	font-size: 2.2rem;
	line-height: 1;

	a {
		color: $dark;
		text-decoration: none;
	}
}

.site-description {
	position: absolute;
	margin-bottom: 0;
	font-size: 1.2rem;
	line-height: 1;
	text-indent: -9999px;

	@include breakpoint(md) {
		line-height: 1.382;
		text-align: left;
	}
}
